import { saveAs } from "file-saver";
import "./ImageListItem.css"
export function ImageListItem({ img }) {
    async function downloadImage() {
        const resp = await fetch(img.download_url);
        const blob = await resp.blob();
        saveAs(blob, img.author + "_" +img.id);
    }
    return (
        <div className="card">
            <a rel="noopener noreferrer" target="_blank" href={img.url}>
                <img src={img.download_url} className="img" alt="" />
            </a>
            <div className="card_banner">
                <img src={img.download_url} className="card_thumb" alt="" />
                <div className="card_text">
                    <h3 className="card_title">{img.author}</h3>
                    <div className="card_status">
                        Taille réelle : {img.height} x {img.width}
                    </div>
                    <button className="btn btn-outline-secondary" onClick={downloadImage}>Télécharger</button>
                </div>
            </div>
        </div>
    );
}